import Loadable from 'react-loadable';
import Loading from '@korian/socle-back-office/dist/components/Loading';

// export const DashboardPage = Loadable({
//   loader: function loader() {
//     return import('../Pages/Dashboard');
//   },
//   loading: Loading,
// });

// // #################### MAPS ENTITIES ############################
export const LogError = Loadable({
    loader: () => import('../Pages/Entities/LogError/LogErrorList'),
    loading: Loading,
});
export const LogHistory = Loadable({
    loader: () => import('../Pages/Entities/LogHistory/LogHistoryList'),
    loading: Loading,
});
export const LogManageList = Loadable({
    loader: () => import('../Pages/Entities/ManageLogs/LogManageList'),
    loading: Loading,
});
export const UsersList = Loadable({
    loader: () => import('../Pages/Entities/Users/UsersList'),
    loading: Loading,
});
export const FacilityList = Loadable({
    loader: () => import('../Pages/Entities/Facility/FacilityList'),
    loading: Loading,
});
export const FacilityTypesList = Loadable({
    loader: () => import('../Pages/Entities/FacilityTypes/FacilityTypesList'),
    loading: Loading,
});
export const ServiceList = Loadable({
    loader: () => import('../Pages/Entities/Service/ServiceList'),
    loading: Loading,
});
export const ServiceCategoryList = Loadable({
    loader: () => import('../Pages/Entities/ServiceCategory/ServiceCategoryList'),
    loading: Loading,
});
export const EventList = Loadable({
    loader: () => import('../Pages/Entities/Event/EventList'),
    loading: Loading,
});
export const EventTypesList = Loadable({
    loader: () => import('../Pages/Entities/EventTypes/EventTypesList'),
    loading: Loading,
});
export const CreateFrenchDataList = Loadable({
    loader: () => import('../Pages/Entities/CreateFrenchData/CreateFrenchDataList'),
    loading: Loading,
});
export const PlaceList = Loadable({
    loader: () => import('../Pages/Entities/Place/PlaceList'),
    loading: Loading,
});
export const PlaceTypesList = Loadable({
    loader: () => import('../Pages/Entities/PlaceTypes/PlaceTypesList'),
    loading: Loading,
});
export const PlaceAutoComplete = Loadable({
    loader: () => import('../Pages/Entities/AutoComplete/PlaceAutoComplete'),
    loading: Loading,
});
export const RequestList = Loadable({
    loader: () => import('../Pages/Entities/Request/RequestList'),
    loading: Loading,
});
export const RestList = Loadable({
    loader: () => import('../Pages/Entities/Rest/RestList'),
    loading: Loading,
});
// ###################### CONNEXION ###########################

export const Login = Loadable({
    loader: function loader() {
        return import('../Pages/Users/Login');
    },
    loading: Loading,
});
export const LoginDedicated = Loadable({
    loader: function loader() {
        return import(
            '@korian/socle-back-office/dist/containers/Pages/Standalone/LoginDedicated'
        );
    },
    loading: Loading,
}); // #################### ERROR VIEWS #############################

export const NotFound = Loadable({
    loader: function loader() {
        return import('../NotFound/NotFound');
    },
    loading: Loading,
});
export const NotFoundDedicated = Loadable({
    loader: function loader() {
        return import(
            '@korian/socle-back-office/dist/containers/Pages/Standalone/NotFoundDedicated'
        );
    },
    loading: Loading,
});
export const Error = Loadable({
    loader: function loader() {
        return import('@korian/socle-back-office/dist/containers/Pages/Error');
    },
    loading: Loading,
});
