import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Fab from '@material-ui/core/Fab';
import Ionicon from 'react-ionicons';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { connect } from 'react-redux';
import Dialog from '@korian/socle-back-office/dist/components/DialogModal/countDownModal';
import UserMenu from './UserMenu';
import styles from './header-jss';

const JWT = require('jsonwebtoken');

const dotenv = require('dotenv');

dotenv.config();

const elem = document.documentElement;

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            open: false,
            fullScreen: false,
            turnDarker: false,
            showTitle: false,
        };
        this.userLogin = {};
    }

  // Initial header style
  flagDarker = false;

  flagTitle = false;

  componentDidMount = () => {
      this.countDownToken();
      window.addEventListener('scroll', this.handleScroll);
  };

  componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
      const doc = document.documentElement;
      const scroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
      const newFlagDarker = scroll > 30;
      const newFlagTitle = scroll > 40;
      if (this.flagDarker !== newFlagDarker) {
          this.setState({ turnDarker: newFlagDarker });
          this.flagDarker = newFlagDarker;
      }
      if (this.flagTitle !== newFlagTitle) {
          this.setState({ showTitle: newFlagTitle });
          this.flagTitle = newFlagTitle;
      }
  };

  openFullScreen = () => {
      this.setState({ fullScreen: true });
      if (elem.requestFullscreen) {
          elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
      /* Firefox */
          elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
          elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
          elem.msRequestFullscreen();
      }
  };

  closeFullScreen = () => {
      this.setState({ fullScreen: false });
      if (document.exitFullscreen) {
          document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
      }
  };

  turnMode = (mode) => {
      const { changeMode } = this.props;
      if (mode === 'light') {
          changeMode('dark');
      } else {
          changeMode('light');
      }
  };

  initCountDown = () => {
      const action = {
          type: 'INIT_COUNTDOWN',
      };
      this.props.dispatch(action);
  };

  readCookie(name) {
      let result = document.cookie.match(new RegExp(`${name}=([^;]+)`));
      if (result) {
          result = JSON.parse(result[1]);
      }
      return result;
  }

  countDownToken = () => {
      // Update the count down every 1 second
      const { userLogin } = this;

      if (userLogin !== null) {
          const x = setInterval(() => {
              // Set the date we're counting down to

              const userInfo = this.props.usersLogin.get('userInfo') !== undefined
                  ? this.props.usersLogin.get('userInfo')
                  : userLogin.userInfo;

              const countDownDate = new Date(userInfo.exp * 1000);
              // Get today's date and time
              const now = new Date().getTime();

              // Find the distance between now and the count down date
              const distance = countDownDate - now;

              // Time calculations for days, hours, minutes and seconds
              const hours = Math.floor(
                  (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
              );
              const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
              const seconds = Math.floor((distance % (1000 * 60)) / 1000);

              // notification condition
              if (minutes <= process.env.REACT_APP_TIME_SESSION_DIALOG) {
                  this.initCountDown();
              }
              // distance = -1;
              // If the count down is finished, write some text
              if (
                  window.document.getElementById('alert-countDown-description') !== null
              ) {
                  if (distance < 0) {
                      clearInterval(x);
                      // console.log('clear interval');
                      window.document
                          .querySelector('#btn-continue')
                          .setAttribute('style', 'display:none');
                      window.document.querySelector('#alert-countDown-title')
                          .innerHTML = '<h2>Votre session est terminée</h2>';
                      window.document.querySelector('#subtitle-dialog').innerHTML = 'Veuillez vous reconnecter';
                      window.document.querySelector(
                          '#alert-countDown-description',
                      ).innerHTML = '';
                      window.document.querySelector(
                          '#alert-countDown-description',
                      ).innerHTML = '00h 00m 00s ';
                      window.document.querySelector('#btn-deconnexion').innerHTML = 'SE RECONNECTER';
                      window.document
                          .querySelector('#dialogAction')
                          .setAttribute('style', 'justify-content:center');
                  } else {
                      window.document.querySelector(
                          '#alert-countDown-description',
                      ).innerHTML = `${hours}h ${minutes}m ${seconds}s `;
                      window.document
                          .querySelector('#dialogAction')
                          .setAttribute('style', 'justify-content:center');
                  }
              }
          }, 1000);
      }
  };

  closeCountDownModal = () => {
      const action = {
          type: 'CLOSE_COUNTDOWN',
      };
      this.props.dispatch(action);
  };

  logout = () => {
      const action = {
          type: 'AUTH_LOGOUT',
      };
      this.props.dispatch(action);
  };

  // JWT decode function
  jwtDecode = (token) => {
      const decoded = JWT.decode(token, { complete: true });
      return decoded;
  };

  refreshTokenSession = () => {
      // console.log('fired');
      const userInfo = this.props.usersLogin.size !== 0 ? this.props.usersLogin : this.userLogin;
      // Show loader
      window.document
          .querySelector('#content-info')
          .setAttribute('style', 'display:none');
      window.document
          .querySelector('#error-response')
          .setAttribute('style', 'display:none');
      window.document
          .querySelector('#loader-box')
          .setAttribute('style', 'display:flex');

      // prepare the request for update token session
      let dbRequest = null;
      if (this.props.usersLogin.size !== 0) {
          dbRequest = new Request(process.env.REACT_APP_REFRESH_TOKEN_URI, {
              method: 'POST',
              body: JSON.stringify({
                  refresh_token: userInfo.get('refreshToken'),
              }),
              headers: new Headers({
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${userInfo.get('token')}`,
              }),
          });
      } else {
          dbRequest = new Request(process.env.REACT_APP_REFRESH_TOKEN_URI, {
              method: 'POST',
              body: JSON.stringify({
                  refresh_token: userInfo.refreshToken,
              }),
              headers: new Headers({
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${userInfo.token}`,
              }),
          });
      }

      fetch(dbRequest)
          .then((response) => {
              if (response.status === 401) {
                  window.document
                      .querySelector('#content-info')
                      .setAttribute('style', 'display:none');
                  window.document
                      .querySelector('#loader-box')
                      .setAttribute('style', 'display:none');
                  window.document.querySelector(
                      '#error-response',
                  ).innerHTML = '<p>Erreur 401 : accès non autorisé </p>';
                  window.document
                      .querySelector('#error-response')
                      .setAttribute('style', 'display:flex');
              }
              if (response.status === 200) {
                  response.json().then(({ token, refreshToken }) => {
                      // when the promise return success
                      // get the token and decode them
                      const decodeToken = this.jwtDecode(token);
                      // console.log(decodeToken);
                      // store datas in each local storage and redirect the user to the dashboard
                      const usersLogin = {
                          connexionTime: new Date().getTime(),
                          userInfo: decodeToken.payload,
                          token,
                          refreshToken,
                          firstConexion: false,
                      };

                      const now = new Date();
                      const dateExpiration = new Date(
                          now.getFullYear,
                          now.getMonth(),
                          now.getDate() + 1,
                          23,
                          59,
                          0,
                      );

                      document.cookie = `usersLogin=${
                          JSON.stringify(usersLogin)
                      }; path=/;expires=${
                          dateExpiration.toUTCString()}`;

                      const action = {
                          type: 'AUTH_LOGIN',
                      };
                      this.props.dispatch(action);

                      this.closeCountDownModal();
                  });
              }
          })
          .catch(() => {
              window.document
                  .querySelector('#content-info')
                  .setAttribute('style', 'display:none');
              window.document
                  .querySelector('#loader-box')
                  .setAttribute('style', 'display:none');
              window.document.querySelector(
                  '#error-response',
              ).innerHTML = '<p>Erreur de communication avec le serveur</p>';
              window.document
                  .querySelector('#error-response')
                  .setAttribute('style', 'display:flex');
          });
  };

  showCountDown() {
      if (this.props.showNotif === true) {
          return (
              <Dialog
                title=""
                content=""
                confirmOff={this.closeCountDownModal.bind(this)}
                logout={this.logout.bind(this)}
                refreshTokenSession={this.refreshTokenSession.bind(this)}
              />
          );
      }
  }

  changeDataTheme = (action, value) => {
      switch (action) {
          case 'updateTheme':
              // console.log(value);
              setTimeout(() => {
                  this.props.dispatch({
                      type: 'CHANGE_THEME',
                      theme: value,
                  });
                  this.props.changeMode(this.props.mode);
              }, 50);
              break;
          case 'updateBgPosition':
              setTimeout(() => {
                  this.props.dispatch({
                      type: 'CHANGE_BG_POSITION',
                      position: value,
                  });
              }, 50);
              break;
          case 'updateDecoration':
              setTimeout(() => {
                  this.props.dispatch({
                      type: 'CHANGE_DECO',
                      deco: value,
                  });
              }, 50);
              break;
          case 'updateGradient':
              setTimeout(() => {
                  this.props.dispatch({
                      type: 'CHANGE_GRADIENT',
                      gradient: value,
                  });
              }, 50);
              break;
          default:
              break;
      }
  };

  render() {
      const {
          classes,
          toggleDrawerOpen,
          margin,
          position,
          gradient,
          mode,
          title,
      } = this.props;

      const {
          fullScreen, open, turnDarker, showTitle,
      } = this.state;
      this.userLogin = this.readCookie('usersLogin');

      const setMargin = (sidebarPosition) => {
          if (sidebarPosition === 'right-sidebar') {
              return classes.right;
          }
          if (sidebarPosition === 'left-sidebar-big') {
              return classes.leftBig;
          }
          return classes.left;
      };

      return (
          <AppBar
            className={classNames(
                classes.appBar,
                classes.floatingBar,
                margin && classes.appBarShift,
                setMargin(position),
                turnDarker && classes.darker,
                gradient ? classes.gradientBg : classes.solidBg,
            )}
          >
              <Toolbar disableGutters={!open}>
                  <Fab
                    size="small"
                    className={classes.menuButton}
                    aria-label="Menu"
                    onClick={toggleDrawerOpen}
                  >
                      <MenuIcon />
                  </Fab>
                  <Hidden smDown>
                      <div className={classes.headerProperties}>
                          <div
                            className={classNames(
                                classes.headerAction,
                                showTitle && classes.fadeOut,
                            )}
                          >
                              {fullScreen ? (
                                  <Tooltip title="Mode normal" placement="bottom">
                                      <IconButton
                                        className={classes.button}
                                        onClick={this.closeFullScreen}
                                      >
                                          <Ionicon icon="ios-qr-scanner" />
                                      </IconButton>
                                  </Tooltip>
                              ) : (
                                  <Tooltip title="Plein ecran" placement="bottom">
                                      <IconButton
                                        className={classes.button}
                                        onClick={this.openFullScreen}
                                      >
                                          <Ionicon icon="ios-qr-scanner" />
                                      </IconButton>
                                  </Tooltip>
                              )}
                              <Tooltip title="Mode sombre/lumineux" placement="bottom">
                                  <IconButton
                                    className={classes.button}
                                    onClick={() => this.turnMode(mode)}
                                  >
                                      <Ionicon icon="ios-bulb-outline" />
                                  </IconButton>
                              </Tooltip>
                          </div>
                          <Typography
                            component="h4"
                            className={classNames(
                                classes.headerTitle,
                                showTitle && classes.show,
                            )}
                          >
                              {title}
                          </Typography>
                      </div>
                      {this.showCountDown()}
                  </Hidden>

                  <Hidden xsDown>
                      <span className={classes.separatorV} />
                  </Hidden>
                  <UserMenu
                    updateTheme={(value) => {
                        this.changeDataTheme('updateTheme', value);
                    }}
                    updateBgPosition={(value) => {
                        this.changeDataTheme('updateBgPosition', value);
                    }}
                    updateDecoration={(value) => {
                        this.changeDataTheme('updateDecoration', value);
                    }}
                    updateGradient={(value) => {
                        this.changeDataTheme('updateGradient', value);
                    }}
                  />
              </Toolbar>
          </AppBar>
      );
  }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    toggleDrawerOpen: PropTypes.func.isRequired,
    margin: PropTypes.bool.isRequired,
    gradient: PropTypes.bool.isRequired,
    position: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    changeMode: PropTypes.func.isRequired,
    openGuide: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

const reducerLogin = 'login';
const reducerUi = 'ui';
const mapStateToProps = (state) => ({
    force: state,
    usersLogin: state.getIn([reducerLogin, 'usersLogin']),
    showNotif: state.getIn([reducerLogin, 'showNotif']),
    deco: state.getIn([reducerUi, 'decoration']),
    layout: state.getIn([reducerUi, 'layout']),
});

const HeaderWithStyles = withStyles(styles)(Header);
export default connect(mapStateToProps)(HeaderWithStyles);

// export default withStyles(styles)(Header);
