/* eslint-disable no-undef */
import { MsalAuthProvider, LoginType } from 'react-aad-msal';

// Msal Configurations
const config = {
    auth: {
        authority: 'https://login.microsoftonline.com/common',
        clientId: 'b100ba02-89ba-4d70-b847-7f9bf69e1a80',
        redirectUri: ApiConfig.azureRedirectUri,
        postLogoutRedirectUri: ApiConfig.azureLogoutRedirectUri,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
    },
};

// Authentication Parameters
const authenticationParameters = {
    scopes: [
        'api://b100ba02-89ba-4d70-b847-7f9bf69e1a80/UserRead',
        'email'
    ],
};


// Options
const options = {
    loginType: LoginType.Popup,
};

const authProvider = new MsalAuthProvider(
    config,
    authenticationParameters,
    options,
);

export default authProvider;
