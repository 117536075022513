import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Ionicon from 'react-ionicons';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@korian/socle-back-office/dist/components/DialogModal';
import { connect } from 'react-redux';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import classNames from 'classnames';
import { Redirect } from 'react-router';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import InputLabel from '@material-ui/core/InputLabel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { IoIosColorWand, IoMdOptions } from 'react-icons/io';
import { MdGradient, MdFormatColorFill } from 'react-icons/md';
import Alert from '@material-ui/lab/Alert';
import {
    ThemeList,
    ThemeGradient,
    ThemeDecoration,
    ThemeBgPosition,
    SelectedTheme,
} from '@korian/socle-back-office/dist/redux/modules/ui';
import styles from './header-jss';

import { getPhoto } from '../../../Pages/MSIProvider';
import authProvider from '../../../Pages/Users/authProvider';

const title = 'Déconnexion';
const content = 'Voulez-vous vraiment vous déconnecter ?';

// This variable is used for manage interval function
let x = null;

function TabPanel(props) {
    const {
        children, value, index, ...other
    } = props;

    return (
        <Typography
          component="div"
          role="tabpanel"
          hidden={value !== index}
          id={`nav-tabpanel-${index}`}
          aria-labelledby={`nav-tab-${index}`}
          {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export const readCookie = (name) => {
    let result = document.cookie.match(new RegExp(`${name}=([^;]+)`));
    if (result) {
        result = JSON.parse(result[1]);
    }
    return result;
};

class UserMenu extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            // anchorEl: null,
            photo: null,
            openMenu: null,
            redirect: false,
            showInfo: false,
            value: 0,
            themeList: ThemeList,
            confirm: false,
            loader: false,
            successUpdateTheme: false,
        };
        const userTheme = readCookie('userTheme');
        if (userTheme !== null) {
            this.state.artDecoration = userTheme.artDecoration;
            this.state.gradient = true;
            this.state.selectedTheme = userTheme.theme;
            this.state.bgPosition = userTheme.bgPosition;
        } else {
            this.state.artDecoration = ThemeDecoration;
            this.state.gradient = ThemeGradient;
            this.state.selectedTheme = SelectedTheme;
            this.state.bgPosition = ThemeBgPosition;
        }
    }

  handleMenu = (menu) => {
      const { openMenu } = this.state;
      this.setState({
          openMenu: openMenu === menu ? null : menu,
      });
  };

  handleClose = () => {
      this.setState({
          openMenu: null,
      });
  };

  closeLogoutModal = () => {
      this.setState({
          confirm: false,
      });
  };

  closeUserInfo = () => {
      this.setState({
          showInfo: false,
      });
  };

  logout = () => {
      // Deconnexion Azure
      if (localStorage.getItem('msiAccessToken') !== null) {
          authProvider.logout();
          localStorage.removeItem('msiAccessToken');
      }
      const action = {
          type: 'AUTH_LOGOUT',
      };
      this.props.dispatch(action);
  };

  confirmModal = () => {
      this.setState({
          confirm: true,
      });
  };

  showUserInfo = () => {
      this.setState({
          showInfo: true,
      });
  };

  saveUserTheme() {
      const usersTheme = {
          theme: this.state.selectedTheme,
          bgPosition: this.state.bgPosition,
          artDecoration: this.state.artDecoration,
          gradient: this.state.gradient,
      };
      const now = new Date();
      const dateExpiration = new Date(
          now.getFullYear,
          now.getMonth(),
          now.getDate() + 1,
          23,
          59,
          0,
      );
      const oldUserTheme = readCookie('userTheme');
      if (oldUserTheme !== null) {
          document.cookie = 'userTheme=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
          // Update cookie
          document.cookie = `userTheme=${
              JSON.stringify(usersTheme)
          }; path=/;expires=${
              dateExpiration.toUTCString()}`;

      // console.log('updated !!');
      } else {
          document.cookie = `userTheme=${
              JSON.stringify(usersTheme)
          }; path=/;expires=${
              dateExpiration.toUTCString()}`;
      // console.log('created !!');
      }
  }

  timeBeforeRefresh(limit) {
      x = setInterval(() => {
          const now = new Date().getTime();
          const diff = limit * 1000 - now;
          const element = document.querySelector('#time');
          if (element !== null) {
              element.innerHTML = `${new Date(diff).getMinutes()}:${new Date(
                  diff,
              ).getSeconds()}`;
          }
      }, 1000);
  }

  componentWillUnmount() {
      clearInterval(x);
  }

  componentDidMount() {
      if (this.userLogin !== null) {
          getPhoto(this.userLogin).then(r => {
              let blobUrl;
              if (r) {
                  const url = window.URL || window.webkitURL;
                  blobUrl = url.createObjectURL(r);
              }
              // document.getElementById('avatar_img').setAttribute('src', blobUrl);
              this.setState({
                  photo: r ? blobUrl : null,
              });
              localStorage.setItem('photoSrc', r ? blobUrl : null);
          });
      }
  }

  render() {
      const { classes } = this.props;

      const {
          photo,
          confirm,
          showInfo,
          value,
      } = this.state;
      this.userLogin = readCookie('usersLogin');
      let userInfo = null;
      let isAzureConnexion = false;
      if (this.userLogin !== null) {
          userInfo = this.userLogin.userInfo;
          // vérifions si l'utilisateur s'est connecté avec azure
          if (localStorage.getItem('msiAccessToken') !== null) {
              isAzureConnexion = true;
          }
          if (x === null) {
              this.timeBeforeRefresh(userInfo.exp);
          } else {
              clearInterval(x);
              this.timeBeforeRefresh(userInfo.exp);
          }
      }
      return (
          <div className={classNames(classes.headerAction, classes.invert)}>
              <Tooltip
                title={
            this.userLogin !== null
                ? `${userInfo.username} (${userInfo.roles[0]})`
                : ''
          }
                placement="bottom"
              >
                  <IconButton className={classes.button} onClick={this.showUserInfo}>
                      {isAzureConnexion === true
                          ? photo === null
                              ? (<Ionicon icon="ios-contact-outline" className={classes.button} />)
                              : (
                                  <img
                                    id="avatar_img"
                                    className={classes.button}
                                    alt={`${userInfo.firstname}_${userInfo.lastname}`}
                                    src={photo}
                                  />
                              )
                          : (<Ionicon icon="ios-contact-outline" className={classes.button} />)}

                  </IconButton>
              </Tooltip>
              {this.state.redirect ? (
                  <Redirect to={`/app/utilisateurs?username=${userInfo.username}`} />
              ) : (
                  false
              )}
              {showInfo ? (
                  <SwipeableDrawer
                    variant="temporary"
                    anchor="right"
                    open={showInfo}
                    onOpen={this.showUserInfo}
                    onClose={this.closeUserInfo}
                    style={{ width: '0% !important' }}
                  >
                      <Tabs
                        value={value}
                        onChange={(event, newValue) => {
                            this.setState({
                                value: newValue,
                            });
                        }}
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="secondary"
                        aria-label="icon label tabs example"

                      >
                          <Tab label="Mes infos" />
                          <Tab label="Thème" />
                      </Tabs>
                      <TabPanel value={value} index={0}>
                          <Card className={classes.card}>
                              <CardHeader
                                avatar={(
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        {photo === null
                                            ? (userInfo.firstname.charAt(0).toUpperCase()
                                        + userInfo.lastname.charAt(0).toUpperCase())
                                            : (
                                                <img
                                                  className={classes.button}
                                                  alt={`${userInfo.firstname}_${userInfo.lastname}`}
                                                  src={photo}
                                                />
                                            )}
                                    </Avatar>
                  )}
                                title={`${userInfo.firstname} ${userInfo.lastname}`}
                                subheader="Infos utilisateurs"
                              />
                              <CardContent>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="div"
                                  >
                                      <p className={classes.infos}>
                                          <b>Username</b>
                                          {' '}
                                          :
                                          {userInfo.username}
                                      </p>
                                      <p className={classes.infos}>
                                          <b>Statut du compte</b>
                                          {' '}
                                          :
                                          {' '}
                                          {userInfo.isDeleted ? 'Inactif' : 'Actif'}
                                      </p>
                                      <p className={classes.infos}>
                                          <b>Rôles</b>
                                          {' '}
                                          :
                                          {userInfo.roles.join(', ')}
                                      </p>
                                      <p className={classes.infos}>
                                          <b>Dernière connexion</b>
                                          {' '}
                                          :
                                          {' '}
                                          {new Date(this.userLogin.connexionTime).toLocaleString()}
                                      </p>
                                      <p className={classes.infos}>
                                          <b>Expiration du token</b>
                                          {' '}
                                          :
                                          {' '}
                                          {new Date(userInfo.exp * 1000).toLocaleString()}
                                      </p>
                                      <p className={classes.infos}>
                                          <b>Temps restant </b>
                                          {' '}
                                          :
                                          <span id="time">--:--</span>
                                      </p>
                                  </Typography>
                              </CardContent>
                          </Card>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                          {this.state.successUpdateTheme ? (
                              <Alert style={{ marginBottom: '15px' }} severity="success">Données sauvegardées</Alert>
                          ) : (
                              false
                          )}

                          {/* THEME STYLE */}
                          <Card className={classes.card}>
                              <CardHeader
                                avatar={(
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <MdFormatColorFill />
                                    </Avatar>
                  )}
                                title="THEME COLOR"
                                subheader="Modifier les couleurs du thème"
                              />
                              <CardContent>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="div"
                                  >
                                      <FormControl className={classes.formControl}>
                                          <InputLabel>Thème</InputLabel>
                                          <Select
                                            value={this.state.selectedTheme}
                                            onChange={(event) => {
                                                this.setState({
                                                    selectedTheme: event.target.value,
                                                });
                                                this.props.updateTheme(event.target.value);
                                            }}
                                          >
                                              {this.state.themeList.map((e) => (
                                                  <MenuItem key={e.name} value={e.value}>
                                                      {e.name.toUpperCase()}
                                                  </MenuItem>
                                              ))}
                                          </Select>
                                      </FormControl>
                                  </Typography>
                              </CardContent>
                          </Card>

                          {/* BACKGROUND STYLE */}
                          <Card className={classes.card}>
                              <CardHeader
                                avatar={(
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <IoIosColorWand />
                                    </Avatar>
                  )}
                                title="BACKGROUND STYLE"
                                subheader="Modifier le style de fond"
                              />
                              <CardContent>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="div"
                                  >
                                      <FormControl className={classes.formControl}>
                                          <InputLabel>Style</InputLabel>
                                          <Select
                                            value={this.state.bgPosition}
                                            onChange={(event) => {
                                                this.setState({
                                                    bgPosition: event.target.value,
                                                });

                                                this.props.updateBgPosition(event.target.value);
                                            }}
                                          >
                                              <MenuItem value="header">HEADER</MenuItem>
                                              <MenuItem value="half">HALF</MenuItem>
                                              <MenuItem value="full">FULL</MenuItem>
                                          </Select>
                                      </FormControl>
                                  </Typography>
                              </CardContent>
                          </Card>

                          {/* ART DECORATION */}
                          <Card className={classes.card}>
                              <CardHeader
                                avatar={(
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <IoMdOptions />
                                    </Avatar>
                  )}
                                title="ART DECORATION"
                                subheader="Activer la décoration"
                              />
                              <CardContent>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="div"
                                  >
                                      <FormControlLabel
                                        className={classes.formControl}
                                        control={(
                                            <Switch
                                              checked={this.state.artDecoration}
                                              onChange={(event) => {
                                                  this.setState({
                                                      artDecoration: event.target.checked,
                                                  });

                                                  this.props.updateDecoration(event.target.checked);
                                              }}
                                              defaultValue={this.state.artDecoration}
                                            />
                      )}
                                        label="Décoration"
                                      />
                                  </Typography>
                              </CardContent>
                          </Card>

                          {/* GRADIENT */}
                          <Card className={classes.card}>
                              <CardHeader
                                avatar={(
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <MdGradient />
                                    </Avatar>
                  )}
                                title="GRADIENT"
                                subheader="Activer les dégradés de couleur"
                              />
                              <CardContent>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="div"
                                  >
                                      <FormControlLabel
                                        className={classes.formControl}
                                        control={(
                                            <Switch
                                              checked={this.state.gradient}
                                              onChange={(event) => {
                                                  this.setState({
                                                      gradient: event.target.checked,
                                                  });

                                                  this.props.updateGradient(event.target.checked);
                                              }}
                                              defaultValue={this.state.gradient}
                                            />
                      )}
                                        label="Gradient"
                                      />
                                  </Typography>
                              </CardContent>
                          </Card>

                          <div className={classes.btn_footer}>
                              <Button
                                variant="contained"
                                size="large"
                                onClick={() => {
                                    this.saveUserTheme();
                                    this.setState({
                                        loader: true,
                                    });

                                    // Stopper le loader
                                    setTimeout(() => {
                                        this.setState({
                                            loader: false,
                                            successUpdateTheme: true,
                                        });

                                        // Faire disparaitre le message
                                        setTimeout(() => {
                                            this.setState({
                                                successUpdateTheme: false,
                                            });
                                        }, 3000);
                                    }, 1500);
                                }}
                              >
                                  Sauvegarder
                                  {' '}
                                  {this.state.loader ? (
                                      <CircularProgress
                                        className={classes.progress}
                                        size={20}
                                        style={{ color: '#e7eff8', marginLeft: '10px' }}
                                      />
                                  ) : (
                                      false
                                  )}
                              </Button>
                          </div>
                      </TabPanel>
                  </SwipeableDrawer>
              ) : (
                  false
              )}

              <Tooltip title="Se deconnecter" placement="bottom">
                  <IconButton onClick={this.confirmModal} className={classes.button}>
                      <Ionicon icon="ios-log-out" className={classes.button} />
                  </IconButton>
              </Tooltip>

              {confirm === true ? (
                  <Dialog
                    title={title}
                    content={content}
                    confirmOff={this.closeLogoutModal.bind(this)}
                    confirmAction={this.logout.bind(this)}
                  />
              ) : (
                  false
              )}
          </div>
      );
  }
}

UserMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    dark: PropTypes.bool,
};

UserMenu.defaultProps = {
    dark: false,
};

const reducerLogin = 'login';
const reducerRouter = 'router';
const mapStateToProps = (state) => ({
    usersLogin: state.getIn([reducerLogin, 'usersLogin']),
    router: state.getIn([reducerRouter, 'location']),
});

const UserMenuWithStyles = withStyles(styles)(UserMenu);

export default connect(mapStateToProps)(UserMenuWithStyles);
