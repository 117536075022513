import React from 'react';
import { PropTypes } from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Base from './Base';
import AuthenticatedRoute from './AuthenticatedRoute';
import Menu from './menu';
import {
    LogError,
    LogHistory,
    LogManageList,
    UsersList,
    FacilityList,
    FacilityTypesList,
    ServiceList,
    ServiceCategoryList,
    EventList,
    EventTypesList,
    CreateFrenchDataList,
    PlaceTypesList,
    PlaceList,
    RequestList,
    RestList,
    Error,
    NotFound,
} from './pageListAsync';

// Lister les différentes interfaces visibles selon l'ordre d'affichage souhaité
const menuComponent = [
    FacilityList,
    FacilityTypesList,
    PlaceList,
    PlaceTypesList,
    RestList,
    RequestList,
    CreateFrenchDataList,
    EventTypesList,
    EventList,
    ServiceCategoryList,
    ServiceList,
    LogError,
    LogManageList,
    LogHistory,
    UsersList,
];

class Application extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            isAuthenticated: false,
            roles: [],
        };

        const userLogin = this.readCookie('usersLogin');
        if (userLogin !== null) {
            this.state.roles = userLogin.userInfo.roles;
            this.state.isAuthenticated = true;

            const userTheme = this.readCookie('userTheme');
            if (userTheme !== null) {
                setTimeout(() => {
                    this.props.dispatch({
                        type: 'ADD_USER_THEME',
                        theme: userTheme.theme,
                        bgPosition: userTheme.bgPosition,
                        artDecoration: userTheme.artDecoration,
                        gradient: userTheme.gradient,
                    });
                    this.props.changeMode('light');
                }, 0);
            }
        }
    }

    readCookie(name) {
        let result = document.cookie.match(new RegExp(`${name}=([^;]+)`));
        if (result) {
            result = JSON.parse(result[1]);
        }
        return result;
    }

    render() {
        const { changeMode, history } = this.props;
        return (
            <Base history={history} changeMode={changeMode}>
                <Switch>
                    {Menu.map((item) => {
                        if (menuComponent[Menu.indexOf(item)] !== undefined) {
                            return (
                                <AuthenticatedRoute
                                  key={item.name}
                                  path={item.link}
                                  component={menuComponent[Menu.indexOf(item)]}
                                  isAuthenticated={this.state.isAuthenticated}
                                  roles={this.state.roles}
                                />
                            );
                        }
                        return null;
                    })}
                    <Route path="/app/forbidden" component={Error} />
                    <AuthenticatedRoute
                      component={NotFound}
                      isAuthenticated={this.state.isAuthenticated}
                      roles={this.state.roles}
                    />
                </Switch>
            </Base>
        );
    }
}

Application.propTypes = {
    changeMode: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

const reducerLogin = 'login';
const reducerRouter = 'router';
const reducerUi = 'ui';
const mapStateToProps = (state) => ({
    usersLogin: state.getIn([reducerLogin, 'usersLogin']),
    router: state.getIn([reducerRouter, 'location']),
    theme: state.getIn([reducerUi, 'theme']),
});

export default connect(mapStateToProps)(Application);
