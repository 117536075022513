import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import logo from '@korian/socle-back-office/public/images/korian-logo2.png';
import brand from '../../brand';
import MainMenu from './MainMenu';
import styles from './sidebar-jss';

import { getPhoto } from '../../../Pages/MSIProvider';
import menu from '../../menu';

export const readCookie = (name) => {
    let result = document.cookie.match(new RegExp(`${name}=([^;]+)`));
    if (result) {
        result = JSON.parse(result[1]);
    }
    return result;
};

class SidebarContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            transform: 0,
            photo: null,
        };
        this.userLogin = readCookie('usersLogin');
    }

  componentDidMount = () => {
      // Scroll content to top
      const mainContent = document.getElementById('sidebar');
      mainContent.addEventListener('scroll', this.handleScroll);
      if (this.userLogin !== null) {
          getPhoto(this.userLogin).then(r => {
              let blobUrl;
              if (r) {
                  const url = window.URL || window.webkitURL;
                  blobUrl = url.createObjectURL(r);
              }
              // document.getElementById('avatar_img').setAttribute('src', blobUrl);
              this.setState({
                  photo: r ? blobUrl : null,
              });
              localStorage.setItem('photoSrc', r ? blobUrl : null);
          });
      }
  };

  componentWillUnmount() {
      const mainContent = document.getElementById('sidebar');
      mainContent.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (event) => {
      const scroll = event.target.scrollTop;
      this.setState({
          transform: scroll,
      });
  };

  readCookie(name) {
      let result = document.cookie.match(new RegExp(`${name}=([^;]+)`));
      if (result) {
          result = JSON.parse(result[1]);
      }
      return result;
  }

  render() {
      const {
          classes,
          turnDarker,
          drawerPaper,
          toggleDrawerOpen,
          loadTransition,
          leftSidebar,
          dataMenu,
          status,
          openMenuStatus,
          isLogin,
      } = this.props;
      const { transform, photo } = this.state;

      const setStatus = (st) => {
          switch (st) {
              case 'online':
                  return classes.online;
              case 'idle':
                  return classes.idle;
              case 'bussy':
                  return classes.bussy;
              default:
                  return classes.offline;
          }
      };

      // get connected user
      const userConnected = this.readCookie('usersLogin');
      let roles = null;
      let isAzureConnexion = false;
      if (userConnected !== null) {
          roles = userConnected.userInfo.roles;
      }
      // vérifions si l'utilisateur s'est connecté avec azure
      if (localStorage.getItem('msiAccessToken') !== null) {
          isAzureConnexion = true;
      }
      return (
          <div
            className={classNames(
                classes.drawerInner,
                !drawerPaper ? classes.drawerPaperClose : '',
            )}
          >
              <div className={classes.drawerHeader}>
                  <NavLink
                    to={menu[0].link}
                    className={classNames(
                        classes.brand,
                        classes.brandBar,
                        turnDarker && classes.darker,
                    )}
                  >
                      <img src={logo} alt={brand.name} />
                      {brand.name}
                  </NavLink>
                  {isLogin && (
                  <div
                    className={classNames(classes.profile, classes.user)}
                    style={{
                        opacity: 1 - transform / 100,
                        marginTop: transform * -0.3,
                    }}
                  >
                      <Avatar
                        className={classNames(classes.avatar, classes.bigAvatar)}
                      >
                          {userConnected !== null && isAzureConnexion === true
                              ? (
                                  photo === null
                                      ? (userConnected.userInfo.firstname.charAt(0).toUpperCase()
                                    + userConnected.userInfo.lastname.charAt(0).toUpperCase())
                                      : (
                                          <img
                                            className={classes.button}
                                            alt={
                                                `${userConnected.userInfo.firstname}_
                                                ${userConnected.userInfo.lastname}`
                                            }
                                            src={photo}
                                          />
                                      )
                              )
                              : ((userConnected.userInfo.firstname.charAt(0).toUpperCase()
                              + userConnected.userInfo.lastname.charAt(0).toUpperCase()))}
                      </Avatar>
                      <div>
                          <h4>
                              {userConnected !== null
                                  ? userConnected.userInfo.username
                                  : ''}
                          </h4>
                          <Button size="small" onClick={openMenuStatus}>
                              <i
                                className={classNames(classes.dotStatus, setStatus(status))}
                              />
                              Connecté
                          </Button>
                      </div>
                  </div>
                  )}
              </div>
              <div
                id="sidebar"
                className={classNames(
                    classes.menuContainer,
                    leftSidebar && classes.rounded,
                    isLogin && classes.withProfile,
                )}
              >
                  <MainMenu
                    loadTransition={loadTransition}
                    dataMenu={dataMenu}
                    rolesUser={roles}
                    toggleDrawerOpen={toggleDrawerOpen}
                  />
              </div>
          </div>
      );
  }
}

SidebarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    drawerPaper: PropTypes.bool.isRequired,
    turnDarker: PropTypes.bool,
    toggleDrawerOpen: PropTypes.func,
    loadTransition: PropTypes.func,
    leftSidebar: PropTypes.bool.isRequired,
    dataMenu: PropTypes.array.isRequired,
    status: PropTypes.string.isRequired,
    anchorEl: PropTypes.object,
    openMenuStatus: PropTypes.func.isRequired,
    closeMenuStatus: PropTypes.func.isRequired,
    changeStatus: PropTypes.func.isRequired,
    isLogin: PropTypes.bool,
};

SidebarContent.defaultProps = {
    turnDarker: false,
    toggleDrawerOpen: () => {},
    loadTransition: () => {},
    anchorEl: null,
    isLogin: true,
};

export default withStyles(styles)(SidebarContent);
