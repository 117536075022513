const { MdAttachFile } = require('react-icons/md');
const { MdViewAgenda } = require('react-icons/md');
const { MdEvent } = require('react-icons/md');
const { MdWidgets } = require('react-icons/md');
const { MdPermDataSetting } = require('react-icons/md');
const { MdLocationCity } = require('react-icons/md');
const { MdDomain } = require('react-icons/md');
const { IoIosConstruct } = require('react-icons/io');
const { IoIosPaper } = require('react-icons/io');
const { IoIosContacts } = require('react-icons/io');
const { MdPlace } = require('react-icons/md');
const { FaMapSigns } = require('react-icons/fa');
const { MdSwapCalls } = require('react-icons/md');
const { FaExchangeAlt } = require('react-icons/fa');
const { BiError } = require('react-icons/bi');

// the library to use for icons

module.exports = [
    {
        key: 'facility',
        name: 'Etablissements',
        link: '/app/facility',
        icon: {
            value: MdLocationCity,
        },
        granted: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    },
    {
        key: 'facility_types',
        name: "Types d'établisements",
        link: '/app/facility_types',
        icon: {
            value: MdDomain,
        },
        granted: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    },
    {
        key: 'places',
        name: 'Emplacements',
        link: '/app/places',
        icon: {
            value: MdPlace,
        },
        granted: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    },
    {
        key: 'place_types',
        name: 'Types emplacements',
        link: '/app/place_types',
        icon: {
            value: FaMapSigns,
        },
        granted: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    },
    {
        key: 'rests',
        name: 'Requêtes REST',
        link: '/app/rests',
        icon: {
            value: FaExchangeAlt,
        },
        granted: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    },
    {
        key: 'requests',
        name: 'Demandes UNIK',
        link: '/app/requests',
        icon: {
            value: MdSwapCalls,
        },
        granted: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    },
    {
        key: 'create_french_data',
        name: 'Importation fichier',
        link: '/app/create_french_data',
        icon: {
            value: MdAttachFile,
        },
        granted: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    },
    {
        key: 'event_types',
        name: "Types d'évenements",
        link: '/app/event_types',
        icon: {
            value: MdViewAgenda,
        },
        granted: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    },
    {
        key: 'events',
        name: 'Evenements',
        link: '/app/events',
        icon: {
            value: MdEvent,
        },
        granted: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    },
    {
        key: 'service_categories',
        name: 'Catégories de services',
        link: '/app/service_categories',
        icon: {
            value: MdWidgets,
        },
        granted: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    },
    {
        key: 'services',
        name: 'Services',
        link: '/app/services',
        icon: {
            value: MdPermDataSetting,
        },
        granted: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    },
    {
        key: 'log_errors',
        name: 'Logs Erreurs',
        link: '/app/log_errors',
        icon: {
            value: BiError,
        },
        granted: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    },
    {
        key: 'gestionnaire_logs',
        name: 'Gestionnaire des logs',
        link: '/app/gestionnaire/logs',
        icon: {
            value: IoIosConstruct,
        },
        granted: ['ROLE_SUPER_ADMIN'],
    },
    {
        key: 'historique_logs',
        name: 'Historique des logs',
        link: '/app/historique/logs',
        icon: {
            value: IoIosPaper,
        },
        granted: ['ROLE_SUPER_ADMIN'],
    },
    {
        key: 'users',
        name: 'Utilisateurs',
        link: '/app/utilisateurs',
        icon: {
            value: IoIosContacts,
        },
        granted: ['ROLE_SUPER_ADMIN'],
    },
    // {
    //   key: 'parametres',
    //   name: 'Paramètres',
    //   link: '/app/activites',
    //   icon: {
    //     value: IoIosSettings
    //   },
    //   granted: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    // }
];
